<template>
  <b-card
    class="companycredit-edit-wrapper"
  >
    <!-- form -->
    <b-form id="companycreditForm" class="edit-form mt-2">
      <b-row>
        <!--公司名称-->
        <b-col md="6">
          <modal-select
              label="公司名称 *"
              type="input"
              v-on:change="fromChildren($event,['company_name','company_id'])"
              :params="['company_name','company_id']"
              modalName="终端客户"
              placeholder="点击选择公司名称"
              v-model="companycredit.company_name"
          >
          </modal-select>
        </b-col>
        <!--店铺名称-->
        <b-col md="6">
          <modal-select
              label="店铺名称 *"
              type="input"
              v-on:change="fromChildren($event,['store_name','store_id'])"
              :params="['store_name','store_id']"
              :company_id="90"
              :cs_company_id="companycredit.company_id"
              modalName="店铺"
              placeholder="点击选择店铺"
              v-model="companycredit.store_name"
          >
          </modal-select>
        </b-col>
        <!--授信日期-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="授信日期"
              label-for="credit_date"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                id="credit_date"
                v-model="companycredit.credit_date"
                class="form-control"
                :config="{enableTime: true,maxDate: 'today'}"
            />
          </b-form-group>
        </b-col>
        <!--授信额度-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="授信额度"
              label-for="limit_amount"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="limit_amount"
                size="sm"
                type="number"
                @blur="fixNumber('limit_amount')"
                v-model="companycredit.limit_amount"
            />
          </b-form-group>
        </b-col>
        <!--授信有效期-->
      </b-row>
      <!--申请备注-->
      <b-row>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="备注"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="memo"
                size="sm"
                v-model="companycredit.memo"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import companycreditStore from './companycreditStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty, second} from '@core/utils/filter'
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    ModalSelect,
  },
  data() {
    return {
      id: ref(0),
      companycredit: ref({}),
    }
  },
  props:{
    cre_id :{
      type : Number,
      default : 0
    },
  },
  methods:{
    getSaveInfo(){
      return this.companycredit
    },
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('companycredit')) store.registerModule('companycredit', companycreditStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companycredit')) store.unregisterModule('companycredit')
    })

    const edit = function() {
      store.dispatch('companycredit/edit', {id: props.cre_id}).then(res => {
        this.companycredit = res.data.data
        if(!this.companycredit.new){
          this.companycredit.credit_date = toDate(this.companycredit.credit_date)
        }
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.companycredit[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const fixNumber = function (type) {
      if(!isEmpty(this.companycredit[type])){
        this.companycredit[type] = Number(this.companycredit[type]).toFixed(2)
      }else {
        this.companycredit[type] = ''
      }
      this.$forceUpdate()
    }

    return {
      edit,
      fromChildren,
      fixNumber,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
    }
  },
  created() {
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
